import React from "react";
import SEO from "src/components/SEO";

import { NAME, BASE_URL } from "src/helpers/config";

import "./PaymentWaiting.scss";

const PaymentWaiting = () => {
  const seoProps = {
    title: `Aguardando confirmação de pagamento - ${NAME}`,
    description:
      "Estamos aguardando a confirmação do pagamento pelo gateway de pagamento. Assim que confirmado, você receberá o acesso",
    canonical: `${BASE_URL}/aguardando-pagamento/`,
    image: `${BASE_URL}/meta/og-image-default.jpg`,
  };

  return (
    <>
      <SEO {...seoProps} />
      <div className="payment-waiting">
        <div className="container">
          <div className="text">
            <h2>Obrigado!</h2>
            <p>Estamos aguardando a confirmação de pagamento.</p>
          </div>
          <div className="footer">
            <img
              loading="lazy"
              src="https://emersonbroga.com/e/assets/emersonbroga-logo-name-white.png"
              alt="Logo Emerson Broga"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentWaiting;
